<template>
  <v-container
    id="login"
    style="height: 100vh"
    class="d-flex justify-center align-center ml-auto mr-auto"
  >
    <v-card
      height="80vh"
      class="d-flex"
      :width="$vuetify.breakpoint.xs ? '100%' : ''"
    >
      <v-img
        max-width="50%"
        src="../assets/Login-Img.jpg"
        :class="$vuetify.breakpoint.xs ? 'd-none' : 'd-block border'"
      >
      </v-img>
      <v-card-text
        class="d-flex justify-center flex-column align-center"
      >
        <div class="d-flex justify-center">
          <v-img
            src="../assets/logo.png"
            max-width="200px"
            class="mb-4"
          >
          </v-img>
        </div>
        <v-form
          ref="form"
          @submit.prevent="login()"
          class="text-center"
          style="max-width: 500px; width: 100%"
        >
          <v-text-field
            v-model="ambiente"
            label="Azienda"
            required
            autocomplete="azienda"
            :maxlength="6"
            counter="6"
          ></v-text-field>
          <v-text-field
            v-model="user"
            label="Username"
            required
            autocomplete="username"
          ></v-text-field>
          <v-text-field
            v-model="pswd"
            label="Password"
            required
            autocomplete="current-password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            @click:append="show = !show"
          ></v-text-field>
          <div class="text-end">
            <span @click="aggiornaPswd()" class="rec-pswd"
              >Recupera password</span
            >
          </div>

          <v-btn
            color="primary"
            class="mt-2"
            :disabled="validate"
            type="submit"
            rounded
          >
            Login
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import router from "../router";

export default {
  name: "Login",
  data: () => ({
    ambiente: "",
    user: "",
    pswd: "",
    show: false,
  }),
  mounted() {
    if (localStorage.getItem("ambiente")) {
      this.ambiente = localStorage.getItem("ambiente");
    }
  },
  computed: {
    validate() {
      if (this.user == "" || this.pswd == "") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    login() {
      this.$store.state.loading = true;
      this.axios
        .post(`${this.$store.state.url}login`, {
          ambiente: this.ambiente, // 999999
          username: this.user, //dbudic
          password: this.pswd, //test123
          livello: "1",
          source: "web",
        })
        .then((response) => {
          this.$store.state.loading = false;
          localStorage.setItem("user", response.data.id_utente);
          localStorage.setItem(
            "tipologie",
            JSON.stringify(response.data.tipologie)
          );
          localStorage.setItem(
            "categorie",
            JSON.stringify(response.data.categorie)
          );
          localStorage.setItem("ambiente", this.ambiente);

          if (response.data.pswd_provvisoria == "1") {
            router.push(`/aggiorna-password`);
          } else {
            this.axios.defaults.headers.common["X-Auth-Token"] =
              response.data.at;
            router.push("/home");
          }
        })
        .catch((error) => {
          this.$store.state.loading = false;
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.Snackbar.show({
                text: error.response.data.message,
              });
            }
          }
        });
    },
    aggiornaPswd() {
      router.push(`/recupera-password`);
    },
  },
};
</script>

<style>
#login .v-image.border {
  border-right: 1px solid rgb(0 0 0 / 20%);
  border-radius: 0;
}

#login .size-l {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -25%);
  width: 70%;
}

#login .v-messages__message {
  text-align: end;
}

#login .logo-xs {
  display: flex;
  align-self: center;
  width: 200px;
}

#login .logo-l {
  margin-top: 100px;
  width: 200px;
}

#login .logo-l .resize-img {
  width: 200px;
}

#login .rec-pswd {
  cursor: pointer;
}

#login .rec-pswd:hover {
  font-weight: bold;
}
</style>