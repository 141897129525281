<template>
  <v-container
    id="riepilogo"
    :style="
      $vuetify.breakpoint.xs
        ? 'height: calc(100vh - 64px); margin-top: 64px'
        : 'height: calc(100vh - 128px); margin-top: 128px'
    "
    :class="interventi.length == 0 ? 'd-flex justify-center align-center' : ''"
  >
    <v-row>
      <v-col cols="12">
        <!-- NUOVO INTERVENTO -->
        <div
          class="text-center"
          v-if="interventi.length == 0 && !$store.state.loading"
        >
          <h2 class="mb-2">Crea il primo intervento</h2>

          <v-btn icon link to="/crea-intervento">
            <v-icon large>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </div>

        <!-- LEGENDA -->
        <v-container
          :class="$vuetify.breakpoint.xs ? 'd-none' : ''"
          v-if="interventi.length != 0 && !$store.state.loading"
        >
          <v-row>
            <v-col cols="6" md="2">
              <div class="my-3 d-flex align-center">
                <v-avatar
                  color="primary"
                  dark
                  size="18"
                  class="mr-3"
                ></v-avatar>
                <span>Ferie/malattia</span>
              </div>
              <div class="d-flex align-center">
                <v-avatar color="orange" dark size="18" class="mr-3"></v-avatar>
                <span>Iniziato</span>
              </div>
            </v-col>
            <v-col cols="6" md="2">
              <div class="my-3 d-flex align-center">
                <v-avatar color="green" dark size="18" class="mr-3"></v-avatar>
                <span>Finito</span>
              </div>
              <div class="d-flex align-center">
                <v-avatar color="red" dark size="18" class="mr-3"></v-avatar>
                <span>Non gestito</span>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="my-3 d-flex align-center">
                <v-avatar color="black" dark size="18" class="mr-3"></v-avatar>
                <span>Intervento non assegnato</span>
              </div>
              <div class="my-3 d-flex align-center">
                <v-avatar
                  tile
                  small
                  style="
                    background-color: lightyellow;
                    border: 1px solid lightgrey;
                  "
                  class="mr-3"
                  max-height="24px"
                >
                </v-avatar>
                <span>Chiuso da admin</span>
              </div>
            </v-col>
          </v-row>
        </v-container>

        <!-- TABELLA -->
        <v-data-table
          v-if="interventi.length != 0 && !$store.state.loading"
          :headers="headers"
          :items="interventi"
          :items-per-page="itemsPerPage"
          sort-by="id_int"
          :sort-desc="true"
          :search="search"
          :custom-filter="filterAll"
          multi-sort
          :height="
            $vuetify.breakpoint.xs
              ? 'calc(100vh - 331px)'
              : 'calc(100vh - 407px)'
          "
          no-results-text="Nessun risultato"
          fixed-header
          :footer-props="{
            'items-per-page-options': [15, 25, 50, -1],
            'items-per-page-text': 'Elementi per pagina',
            'items-per-page-all-text': 'Tutti',
          }"
          @current-items="getData"
          :item-class="itemRowBackground"
          :loading="caricamentoDati"
        >
          <template v-slot:top>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Cerca"
                single-line
                hide-details
                class="search"
                :clearable="true"
              ></v-text-field>

              <!-- FILTER -->
              <v-dialog v-model="filterDialog" persistent max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-show="!checkFilters"
                    ><v-icon @click="clearFilter()"
                      >mdi-filter-remove-outline</v-icon
                    ></v-btn
                  >
                  <v-btn icon
                    ><v-icon v-bind="attrs" v-on="on"
                      >mdi-filter-outline</v-icon
                    ></v-btn
                  >
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Filtra per:</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="filtersObj.id_int"
                            label="Numero intervento"
                            inputmode="numeric"
                            pattern="[0-9]*"
                            @keypress="isInteger($event)"
                            :rules="[
                              (v) =>
                                !v ||
                                /^(\s*|\d+)$/.test(v) ||
                                'Inserire solo numeri',
                            ]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-select
                            :items="utenti"
                            item-text="nome_cognome"
                            item-value="nome_cognome"
                            label="Dipendente"
                            v-model="filtersObj.nome_cognome"
                            multiple
                            small-chips
                            deletable-chips
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-select
                            :items="tipologie"
                            item-text="nome"
                            item-value="nome"
                            label="Tipologia"
                            v-model="filtersObj.tipologia"
                            multiple
                            small-chips
                            deletable-chips
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-select
                            :items="clienti"
                            item-text="rag_soc"
                            item-value="rag_soc"
                            label="Cliente"
                            v-model="filtersObj.rag_soc"
                            multiple
                            small-chips
                            deletable-chips
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="filtersObj.indirizzo"
                            label="Indirizzo"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-select
                            :items="categorie"
                            item-text="categoria"
                            item-value="categoria"
                            label="Categoria"
                            v-model="filtersObj.categoria"
                            multiple
                            small-chips
                            deletable-chips
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="8">
                          <v-menu
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            ref="menu"
                            :return-value.sync="dates2"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="dateRangeText"
                                label="Dalla data - alla data"
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                readonly
                                v-on="on"
                                @click="dateRangeText = ''"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="dates2"
                              range
                              :min="minDate"
                              first-day-of-week="1"
                              locale="it-IT"
                              ><v-btn
                                tile
                                color="success"
                                class="ml-auto"
                                small
                                @click="confirmDates()"
                              >
                                OK
                              </v-btn></v-date-picker
                            >
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-select
                            :items="status"
                            item-text="text"
                            item-value="value"
                            label="Stato"
                            v-model="filtersObj.gestito"
                            multiple
                            small-chips
                            deletable-chips
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions
                    :class="$vuetify.breakpoint.xs ? 'flex-wrap' : ''"
                  >
                    <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
                    <v-btn color="blue darken-1" text @click="clearFilter()">
                      Cancella tutto
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="filterDialog = false"
                    >
                      Chiudi
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="filterDialog = false"
                      :class="$vuetify.breakpoint.xs ? 'ml-0' : ''"
                    >
                      Conferma
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-spacer></v-spacer>
              <v-btn icon link to="/crea-intervento">
                <v-icon>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-card-title>
          </template>

          <!-- COLONNE DELLA TABELLA -->
          <template v-slot:[`item.gestito`]="{ item }">
            <v-avatar
              :color="getColor(item)"
              dark
              size="18"
              class="mr-3"
            ></v-avatar>
          </template>
          <template v-slot:[`item.inizio_tel`]="{ item }">
            {{ item.inizio_tel.replace(",", "") }}

            <v-icon
              :small="!$vuetify.breakpoint.xs"
              @click="openImage(item.id_intervento, item.id_utente, 'i')"
              v-if="item.foto_inizio == '1'"
            >
              mdi-camera
            </v-icon>
          </template>
          <template v-slot:[`item.inizio_serv`]="{ item }">
            {{ item.inizio_serv.replace(",", "") }}
          </template>
          <template v-slot:[`item.termine_tel`]="{ item }">
            {{ item.termine_tel.replace(",", "") }}
            <v-icon
              :small="!$vuetify.breakpoint.xs"
              @click="openImage(item.id_intervento, item.id_utente, 't')"
              v-if="item.foto_termine == '1'"
            >
              mdi-camera
            </v-icon>
          </template>
          <template v-slot:[`item.termine_serv`]="{ item }">
            {{ item.termine_serv.replace(",", "") }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              :small="!$vuetify.breakpoint.xs"
              class="mr-2"
              @click="$refs.componente.modificaIntervento(item, interventi)"
              v-if="checkStato(item.id_int)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              :small="!$vuetify.breakpoint.xs"
              class="mr-2"
              @click="chiudiIntervento(item)"
              v-if="item.gestito <= 1 && item.gestito != null"
            >
              mdi-close
            </v-icon>
            <v-icon
              :small="!$vuetify.breakpoint.xs"
              @click="cancellaIntervento(item)"
              v-if="
                item.gestito == 0 || item.gestito == null || item.gestito == 3
              "
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:[`item.dettaglio`]="{ item }">
            <v-btn
              icon
              link
              :to="`/dettaglio-intervento/${item.id_int}`"
              v-if="
                item.gestito > 0 && item.gestito != null && item.gestito != 3
              "
            >
              <v-icon> mdi-chevron-right </v-icon>
            </v-btn>
          </template>
          <template v-slot:[`footer.prepend`]>
            <v-btn
              @click="downloadCSV()"
              text
              class="ml-4 px-2"
              color="grey darken-3"
              v-if="!$vuetify.breakpoint.xs"
            >
              Esporta
              <v-icon right> mdi-download </v-icon>
            </v-btn>
            <v-btn
              @click="downloadCSV()"
              text
              class="ml-4 px-2"
              color="grey darken-3"
              v-if="$vuetify.breakpoint.xs"
            >
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </template></v-data-table
        >

        <!-- DIALOG AGGIUNGI/MODIFICA -->
        <InterventoForm ref="componente" @get-interventi="getInterventi" />

        <!-- ALERT DI CHIUSURA INTERVENTO -->
        <v-dialog v-model="dialogChiusura" max-width="550px" persistent>
          <v-card>
            <v-card-title
              :class="
                $vuetify.breakpoint.xs
                  ? 'subtitle-1 justify-center text-center delete'
                  : 'text-h6 justify-center text-center delete'
              "
              style="word-break: break-word"
              >Seleziona l'orario di inizio e fine per poter chiudere
              l'intervento del cliente "{{ interventoDaChiudere.rag_soc }}"
              della data {{ interventoDaChiudere.data_inizio }} assegnato a "{{
                interventoDaChiudere.nome_cognome
              }}"</v-card-title
            >
            <v-card-text>
              <v-form ref="formChiusura" v-model="valid">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="interventoDaChiudere.date_start"
                            label="Inizio intervento"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="[(v) => !!v || 'Campo obbligatorio']"
                            :disabled="true"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="interventoDaChiudere.date_start"
                          @input="menu1 = false"
                          :min="
                            interventoDaChiudere.date_finish
                              ? interventoDaChiudere.date_finish
                              : current_date
                          "
                          :max="
                            interventoDaChiudere.date_finish
                              ? interventoDaChiudere.date_finish
                              : ''
                          "
                          locale="it-IT"
                          first-day-of-week="1"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="interventoDaChiudere.hour_start"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="interventoDaChiudere.hour_start"
                            label="Ora inizio"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="[(v) => !!v || 'Campo obbligatorio']"
                            :disabled="interventoDaChiudere.gestito == 1"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menu2"
                          v-model="interventoDaChiudere.hour_start"
                          full-width
                          @click:minute="
                            $refs.menu2.save(interventoDaChiudere.hour_start)
                          "
                          :max="interventoDaChiudere.hour_finish"
                          format="24hr"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="interventoDaChiudere.date_finish"
                            label="Fine intervento"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="[(v) => !!v || 'Campo obbligatorio']"
                            :disabled="true"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="interventoDaChiudere.date_finish"
                          @input="menu3 = false"
                          :min="interventoDaChiudere.date_start"
                          :max="interventoDaChiudere.date_start"
                          locale="it-IT"
                          first-day-of-week="1"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-menu
                        ref="menu4"
                        v-model="menu4"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="interventoDaChiudere.hour_finish"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="interventoDaChiudere.hour_finish"
                            label="Ora fine"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="[(v) => !!v || 'Campo obbligatorio']"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menu4"
                          v-model="interventoDaChiudere.hour_finish"
                          full-width
                          format="24hr"
                          @click:minute="
                            $refs.menu4.save(interventoDaChiudere.hour_finish)
                          "
                          :min="
                            interventoDaChiudere.date_start ==
                            interventoDaChiudere.date_finish
                              ? interventoDaChiudere.hour_start
                              : ''
                          "
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDialog()"
                >Chiudi</v-btn
              >
              <v-btn
                color="blue darken-1"
                text
                @click="chiudiInterventoConferma()"
                :loading="buttonLoading"
                :disabled="!valid"
                >Conferma</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- DELETE DIALOG -->
        <v-dialog v-model="dialogDelete" max-width="550px" persistent>
          <v-card>
            <v-card-subtitle
              v-if="interventoRic && deleteAll == null"
              class="d-flex flex-column align-center"
            >
              <span class="text-h6">Intervento ricorrente: </span>
              <v-btn
                small
                color="primary"
                dark
                @click="deleteAll = false"
                v-if="deleteAll == null"
                width="240px"
              >
                Cancella singolo intervento
              </v-btn>
              <v-btn
                small
                color="primary"
                dark
                @click="deleteAll = true"
                class="mt-2"
                width="240px"
              >
                Cancella tutti gli interventi
              </v-btn>
            </v-card-subtitle>
            <v-card-title
              v-if="
                (!interventoRic || deleteAll === false) &&
                interventoDelete.id_tipo_int != 7 &&
                interventoDelete.id_tipo_int != 8
              "
              :class="
                $vuetify.breakpoint.xs
                  ? 'subtitle-1 justify-center text-center delete'
                  : 'text-h6 justify-center text-center delete'
              "
              style="word-break: break-word"
              >Sei sicuro di voler cancellare l'intervento del cliente "{{
                interventoDelete.rag_soc
              }}" della data {{ interventoDelete.data_inizio }}?</v-card-title
            >
            <v-card-title
              style="word-break: break-word"
              :class="
                $vuetify.breakpoint.xs
                  ? 'subtitle-1 justify-center text-center delete'
                  : 'text-h6 justify-center text-center delete'
              "
              v-if="
                interventoDelete.id_tipo_int == 7 ||
                interventoDelete.id_tipo_int == 8
              "
            >
              Sei sicuro di voler cancellare l'intervento assegnato a "{{
                interventoDelete.nome_cognome
              }}"?
            </v-card-title>
            <v-card-text
              v-if="
                (!interventoRic || deleteAll === false) &&
                interventoDelete.id_utente != '' &&
                interventoDelete.id_tipo_int != 7 &&
                interventoDelete.id_tipo_int != 8
              "
              class="text-center"
            >
              L'intervento non sarà più assegnato a "{{
                interventoDelete.nome_cognome
              }}". Nel caso in cui l'intervento sia stato associato a più
              dipendenti e si voglia eliminarlo definitivamente, ripetere la
              stessa procedura per ogni dipendente.
            </v-card-text>

            <v-card-text
              v-if="
                (!interventoRic || deleteAll === false) &&
                interventoDelete.id_utente == '' &&
                interventoDelete.id_tipo_int != 7 &&
                interventoDelete.id_tipo_int != 8
              "
              class="text-center"
            >
              Nel caso in cui l'intervento sia stato associato a più dipendenti
              e si voglia eliminarlo definitivamente, ripetere la stessa
              procedura per ogni dipendente.
            </v-card-text>

            <v-card-title
              v-if="deleteAll === true"
              :class="
                $vuetify.breakpoint.xs
                  ? 'subtitle-1 justify-center text-center delete'
                  : 'text-h6 justify-center text-center delete'
              "
              style="word-break: break-word"
              >Sei sicuro di voler cancellare tutti gli interventi ricorrenti
              legati all'intervento del cliente "{{ interventoDelete.rag_soc }}"
              della data {{ interventoDelete.data_inizio }}?</v-card-title
            >

            <v-card-text
              v-if="deleteAll === true && interventoDelete.id_utente != ''"
              class="text-center"
            >
              Gli interventi non saranno più assegnati a "{{
                interventoDelete.nome_cognome
              }}". Nel caso in cui gli interventi siano stati assegnati a più
              dipendenti e si voglia eliminarli definitivamente, ripetere la
              stessa procedura per ogni dipendente.
            </v-card-text>

            <v-card-text
              v-if="deleteAll === true && interventoDelete.id_utente == ''"
              class="text-center"
            >
              Nel caso in cui gli interventi siano stati assegnati a più
              dipendenti e si voglia eliminarli definitivamente, ripetere la
              stessa procedura per ogni dipendente.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete()"
                >Chiudi</v-btn
              >
              <v-btn
                v-if="!interventoRic || deleteAll != null"
                color="blue darken-1"
                text
                @click="cancellaInterventoConferma()"
                :loading="buttonLoading"
                >Conferma</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- EXPORT DIALOG -->
        <v-dialog v-model="dialogCSV" max-width="500px">
          <v-card>
            <v-card-title
              :class="$vuetify.breakpoint.xs ? 'text-h6 pt-4' : 'headline pt-4'"
              >Download CSV</v-card-title
            >
            <v-card-text>
              <div class="mt-4">
                <p style="display: inline">File .csv pronto!</p>
                <a
                  :download="'Interventi-' + dateNow + '.csv'"
                  :href="fileCSV"
                  style="text-decoration: none; display: inline"
                  class="ml-4"
                >
                  <v-btn
                    @click="dialogCSV = false"
                    color="primary"
                    v-if="$vuetify.breakpoint.xs"
                  >
                    <v-icon> mdi-download </v-icon>
                  </v-btn>
                  <v-btn
                    @click="dialogCSV = false"
                    color="primary"
                    v-if="!$vuetify.breakpoint.xs"
                  >
                    Download
                    <v-icon right> mdi-download </v-icon>
                  </v-btn>
                </a>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialogCSV = false"
                >Chiudi</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- PHOTO DIALOG -->
        <v-dialog v-model="imgDialog" max-width="450px">
          <v-card>
            <v-card-text class="pa-4">
              <v-img
                :src="imgLink"
              ></v-img
            ></v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InterventoForm from "../components/InterventoForm";
var dayjs = require("dayjs");
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  name: "Riepilogo",
  components: { InterventoForm },
  data() {
    return {
      tipologie: [],
      categorie: [],
      interventi: [],
      clienti: [],
      utenti: [],
      headers: [
        {
          text: "No.",
          align: "start",
          value: "id_int",
          filter: (value) => {
            if (!this.filtersObj.id_int) return true;
            return value.toString().includes(this.filtersObj.id_int);
          },
          width: "5%",
        },
        {
          text: "Dipendente",
          value: "nome_cognome",
          filter: (value) => {
            if (this.filtersObj.nome_cognome.length == 0) return true;
            return this.filtersObj.nome_cognome.includes(value);
          },
        },
        {
          text: "Tipologia",
          value: "nome_tipo",
          filter: (value) => {
            if (this.filtersObj.tipologia.length == 0) return true;
            return this.filtersObj.tipologia.includes(value);
          },
        },
        {
          text: "Cliente",
          value: "rag_soc",
          filter: (value) => {
            if (this.filtersObj.rag_soc.length == 0) return true;
            return this.filtersObj.rag_soc.includes(value);
          },
        },
        {
          text: "Indirizzo cliente",
          value: "indirizzo",
          filter: (value) => {
            if (!this.filtersObj.indirizzo) return true;
            if (value != null) {
              return value
                .toLowerCase()
                .includes(this.filtersObj.indirizzo.toLowerCase());
            }
          },
        },
        {
          text: "Categoria",
          value: "categoria",
          filter: (value) => {
            if (this.filtersObj.categoria.length == 0) return true;
            return this.filtersObj.categoria.includes(value);
          },
        },
        {
          text: "Inizio pianificato",
          value: "data_ora_inizio",
          filter: (value, search, item) => {
            return this.checkData(item);
          },
          sort: (a, b) => {
            if (a == null || a == undefined || a == "") {
              a = "01/01/1970 00:00";
            }
            if (b == null || b == undefined || b == "") {
              b = "01/01/1970 00:00";
            }
            return (
              new Date(
                dayjs(a, "DD/MM/YYYY HH:mm").format("YYYY/MM/DD HH:mm")
              ) -
              new Date(dayjs(b, "DD/MM/YYYY HH:mm").format("YYYY/MM/DD HH:mm"))
            );
          },
        },
        {
          text: "Data/ora inizio",
          value: "inizio_tel",
          filter: (value, search, item) => {
            return this.checkData(item);
          },
          sort: (a, b) => {
            if (a == null || a == undefined || a == "") {
              a = "01/01/1970 00:00";
            }
            if (b == null || b == undefined || b == "") {
              b = "01/01/1970 00:00";
            }
            return (
              new Date(
                dayjs(a, "DD/MM/YYYY HH:mm").format("YYYY/MM/DD HH:mm")
              ) -
              new Date(dayjs(b, "DD/MM/YYYY HH:mm").format("YYYY/MM/DD HH:mm"))
            );
          },
        },
        {
          text: "Caricamento inizio",
          value: "inizio_serv",
          filter: (value, search, item) => {
            return this.checkData(item);
          },
          sort: (a, b) => {
            if (a == null || a == undefined || a == "") {
              a = "01/01/1970 00:00";
            }
            if (b == null || b == undefined || b == "") {
              b = "01/01/1970 00:00";
            }
            return (
              new Date(
                dayjs(a, "DD/MM/YYYY HH:mm").format("YYYY/MM/DD HH:mm")
              ) -
              new Date(dayjs(b, "DD/MM/YYYY HH:mm").format("YYYY/MM/DD HH:mm"))
            );
          },
        },
        {
          text: "Fine pianificato",
          value: "data_ora_termine",
          filter: (value, search, item) => {
            return this.checkData(item);
          },
          sort: (a, b) => {
            if (a == null || a == undefined || a == "") {
              a = "01/01/1970 00:00";
            }
            if (b == null || b == undefined || b == "") {
              b = "01/01/1970 00:00";
            }
            return (
              new Date(
                dayjs(a, "DD/MM/YYYY HH:mm").format("YYYY/MM/DD HH:mm")
              ) -
              new Date(dayjs(b, "DD/MM/YYYY HH:mm").format("YYYY/MM/DD HH:mm"))
            );
          },
        },
        {
          text: "Data/ora fine",
          value: "termine_tel",
          filter: (value, search, item) => {
            return this.checkData(item);
          },
          sort: (a, b) => {
            if (a == null || a == undefined || a == "") {
              a = "01/01/1970 00:00";
            }
            if (b == null || b == undefined || b == "") {
              b = "01/01/1970 00:00";
            }
            return (
              new Date(
                dayjs(a, "DD/MM/YYYY HH:mm").format("YYYY/MM/DD HH:mm")
              ) -
              new Date(dayjs(b, "DD/MM/YYYY HH:mm").format("YYYY/MM/DD HH:mm"))
            );
          },
        },
        {
          text: "Caricamento fine",
          value: "termine_serv",
          filter: (value, search, item) => {
            return this.checkData(item);
          },
          sort: (a, b) => {
            if (a == null || a == undefined || a == "") {
              a = "01/01/1970 00:00";
            }
            if (b == null || b == undefined || b == "") {
              b = "01/01/1970 00:00";
            }
            return (
              new Date(
                dayjs(a, "DD/MM/YYYY HH:mm").format("YYYY/MM/DD HH:mm")
              ) -
              new Date(dayjs(b, "DD/MM/YYYY HH:mm").format("YYYY/MM/DD HH:mm"))
            );
          },
        },
        {
          text: "Stato",
          value: "gestito",
          filter: (value) => {
            if (this.filtersObj.gestito.length == 0) return true;
            return this.filtersObj.gestito.includes(value);
          },
          sort: (a, b) => {
            if (a == null) {
              a = 4;
            }
            if (b == null) {
              b = 4;
            }
            return a - b;
          },
        },
        { text: "Azioni", value: "actions", sortable: false },
        { text: "", value: "dettaglio", sortable: false },
      ],
      status: [
        { text: "Non gestito", value: 0 },
        { text: "Iniziato", value: 1 },
        { text: "Finito", value: 2 },
        { text: "Ferie/malattia", value: 3 },
        { text: "Non assegnato", value: null },
      ],
      itemsPerPage: 15,
      search: "",
      filterDialog: false,
      filtersObj: {
        id_int: "",
        nome_cognome: [],
        tipologia: [],
        rag_soc: [],
        indirizzo: "",
        categoria: [],
        dates: [],
        gestito: [],
      },
      dates: [],
      dates2: [],
      dateRangeText: "",
      current_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      buttonLoading: false,
      valid: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateError: "",
      errorDialog: false,
      currentItems: [],
      dialogChiusura: false,
      dialogDelete: false,
      interventoDelete: {
        id_int: "",
        id_utente: "",
        nome_cognome: "",
        id_riferimento: "",
        ricorrenza: "",
        rag_soc: "",
        data_inizio: "",
        id_tipo_int: "",
      },
      interventoDaChiudere: {
        id_int: "",
        id_utente: "",
        nome_cognome: "",
        data_inizio: "",
        inizio_tel: "",
        termine_tel: "",
        date_start: "",
        hour_start: "",
        date_finish: "",
        hour_finish: "",
        gestito: "",
        rag_soc: "",
      },
      dialogCSV: false,
      dateNow: "",
      fileCSV: null,
      interventoRic: false,
      deleteAll: null,
      interval: null,
      caricamentoDati: false,
      imgDialog: false,
      imgLink: "",
    };
  },
  mounted() {
    this.$store.state.loading = true;
    this.tipologie = JSON.parse(localStorage.getItem("tipologie"));
    this.categorie = JSON.parse(localStorage.getItem("categorie"));
    this.categorie.forEach((el, i) => {
      if (el.categoria == "Imprevisto") {
        this.categorie.splice(i, 1);
      }
    });

    this.getInterventi();
    this.startInterval();
  },
  computed: {
    checkFilters() {
      const isEmpty = Object.values(this.filtersObj).every(
        (x) =>
          x === null ||
          x === "" ||
          x.length === 0 ||
          (x[0] === null && x[1] === null)
      );

      if (isEmpty) {
        return true;
      } else {
        return false;
      }
    },
    minDate() {
      if (this.dates2.length == 1) {
        return new Date(this.dates2[0]).toISOString().substr(0, 10);
      }
      return "";
    },
  },
  methods: {
    startInterval() {
      this.interval = setInterval(() => {
        this.getInterventi();
      }, 300000);
    },
    getInterventi() {
      if (this.interval) {
        this.caricamentoDati = true;
      } else {
        this.$store.state.loading = true;
      }
      this.axios
        .get(`${this.$store.state.url}interventi`, {
          params: {
            id_utente: localStorage.getItem("user"),
            livello: "1",
          },
        })
        .then((response) => {
          this.interventi = response.data.interventi;
          this.clienti = response.data.clienti;
          this.utenti = response.data.utenti;
          this.interventi.map((el) => {
            Object.keys(el).forEach(function (key) {
              if (el[key] === null && key != "gestito") {
                el[key] = "";
              }
            });
            el["nome_cognome"] = `${el.nome_dip} ${el.cognome}`;

            el["data_ora_inizio"] = `${el.data_inizio} ${el.ora_inizio.slice(
              0,
              -3
            )}`;
            if (el.data_termine != "") {
              el["data_ora_termine"] = `${
                el.data_termine
              } ${el.ora_termine.slice(0, -3)}`;
            }
            el["inizio_tel"] = el.inizio_tel.slice(0, -3);
            el["termine_tel"] = el.termine_tel.slice(0, -3);
            el["inizio_serv"] = el.inizio_serv.slice(0, -3);
            el["termine_serv"] = el.termine_serv.slice(0, -3);
          });
          this.$store.state.loading = false;
          this.caricamentoDati = false;
          this.search = this.$store.state.searchInterventi;
          this.filtersObj = this.$store.state.filtersInterventi;
          this.dateRangeText = this.filtersObj.dates.join(" ~ ");
        })
        .catch((error) => {
          this.$store.state.loading = false;
          this.caricamentoDati = false;
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.$emit("logout", error.response.data.message);
            }
          }
        });
    },
    getColor(item) {
      if (item.gestito == 2) {
        return "green";
      } else if (item.gestito == 1) {
        return "orange";
      } else if (item.gestito == 0) {
        return "red";
      } else if (item.gestito == 3) {
        return "primary";
      } else {
        return "black";
      }
    },
    openImage(intervento, utente, tipo) {
      this.axios
        .get(`${this.$store.state.url}photo`, {
          params: {
            id_utente: localStorage.getItem("user"),
            id_intervento: intervento,
            id_dipendente: utente,
            tipo: tipo,
          },
        })
        .then((res) => {
          if (res.data.photo != "") {
            this.imgLink = res.data.photo;
            this.imgDialog = true;
          }
        })
        .catch((error) => {
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.$emit("logout", error.response.data.message);
            }
            if (error.response.status == 404) {
              this.$root.Snackbar.show({
                text: error.response.data.message,
              });
            }
          }
        });
    },
    itemRowBackground(item) {
      return item.chiusura_admin == 1 ? "yellow-bg" : "";
    },
    checkStato(intervento) {
      var status = [];
      this.interventi.forEach((el) => {
        if (el.id_int == intervento) {
          status.push(el.gestito);
        }
      });
      if (status.includes(1) || status.includes(2)) {
        return false;
      }
      return true;
    },
    checkStatoRic(intervento, utente, riferimento) {
      var status = [];
      this.interventi.forEach((el) => {
        if (el.id_utente == utente) {
          if (riferimento == "") {
            if (el.id_riferimento == intervento) {
              status.push(el.gestito);
            }
          } else {
            if (
              (el.id_riferimento == riferimento && el.Id != intervento) ||
              el.id_int == riferimento
            ) {
              status.push(el.gestito);
            }
          }
        }
      });

      if (status.includes(1) || status.includes(2)) {
        return false;
      }
      return true;
    },
    isInteger(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    confirmDates() {
      if (this.$refs.menu != undefined) {
        this.$refs.menu.save(this.dates2);
      }
      this.dates = [];
      this.dateRangeText = "";
      this.dates.push(this.dates2[0]);
      if (this.dates2.length == 1) {
        this.dates.push(this.current_date);
        this.dates2.push(this.current_date);
      } else {
        this.dates.push(this.dates2[1]);
      }

      this.dates[0] = dayjs(this.dates[0]).format("DD/MM/YYYY");
      this.dates[1] = dayjs(this.dates[1]).format("DD/MM/YYYY");
      this.filtersObj.dates = this.dates;
      this.dateRangeText = this.dates.join(" ~ ");
    },
    checkData(item) {
      if (!this.filtersObj["dates"] || this.filtersObj["dates"].length == 0)
        return true;
      var date1 = this.filtersObj["dates"][0].split("/");
      var date2 = this.filtersObj["dates"][1].split("/");
      var inizio_pianificato = item["data_inizio"];
      var checkInizioPian = inizio_pianificato.split("/");

      var check2 = "";
      var check3 = "";
      var check4 = "";
      var check5 = "";
      var check6 = "";

      if (item["data_termine"] != undefined) {
        var termine_pianificato = item["data_termine"];
        var checkTerminePian = termine_pianificato.split("/");
        check2 = new Date(
          checkTerminePian[2],
          parseInt(checkTerminePian[1]) - 1,
          checkTerminePian[0]
        );
      }

      if (item["inizio_tel"] != undefined) {
        var inizio = item["inizio_tel"].split(", ")[0];
        var checkInizio = inizio.split("/");
        check3 = new Date(
          checkInizio[2],
          parseInt(checkInizio[1]) - 1,
          checkInizio[0]
        );
      }

      if (item["termine_tel"] != undefined) {
        var termine = item["termine_tel"].split(", ")[0];
        var checkTermine = termine.split("/");
        check4 = new Date(
          checkTermine[2],
          parseInt(checkTermine[1]) - 1,
          checkTermine[0]
        );
      }

      if (item["inizio_serv"] != undefined) {
        var inizioServ = item["inizio_serv"].split(", ")[0];
        var checkInizioServ = inizioServ.split("/");
        check5 = new Date(
          checkInizioServ[2],
          parseInt(checkInizioServ[1]) - 1,
          checkInizioServ[0]
        );
      }

      if (item["termine_serv"] != undefined) {
        var termineServ = item["termine_serv"].split(", ")[0];
        var checkTermineServ = termineServ.split("/");
        check6 = new Date(
          checkTermineServ[2],
          parseInt(checkTermineServ[1]) - 1,
          checkTermineServ[0]
        );
      }

      var from = new Date(date1[2], parseInt(date1[1]) - 1, date1[0]); // -1 because months are from 0 to 11
      var to = new Date(date2[2], parseInt(date2[1]) - 1, date2[0]);

      var check1 = new Date(
        checkInizioPian[2],
        parseInt(checkInizioPian[1]) - 1,
        checkInizioPian[0]
      );

      if (
        (check1 >= from && check1 <= to) ||
        (check2 >= from && check2 <= to) ||
        (check3 >= from && check3 <= to) ||
        (check4 >= from && check4 <= to) ||
        (check5 >= from && check5 <= to) ||
        (check6 >= from && check6 <= to)
      ) {
        return true;
      }
    },
    filterAll(valore, search, item) {
      var results = [];
      var columns = [];

      this.headers.forEach((element) => {
        columns.push(element.value);
      });

      Object.entries(item).forEach(([key, value]) => {
        if (typeof value === "number") {
          value = value.toString();
        }

        if (
          value != undefined &&
          value != null &&
          value != "" &&
          columns.includes(key) &&
          search != null &&
          typeof value === "string" &&
          value.toLowerCase().includes(search.toLowerCase())
        ) {
          results.push("true");
        }
      });
      return results.includes("true");
    },
    clearFilter() {
      this.dates = [];
      this.dates2 = [];
      this.dateRangeText = "";
      this.filtersObj = {
        id_int: "",
        nome_cognome: [],
        tipologia: [],
        rag_soc: [],
        indirizzo: "",
        categoria: [],
        dates: [],
        gestito: [],
      };
    },
    chiudiIntervento(item) {
      if (this.$refs.formChiusura) {
        this.$refs.formChiusura.resetValidation();
      }
      this.interventoDaChiudere = Object.assign({}, item);
      if (item.gestito == 1) {
        [
          this.interventoDaChiudere.date_start,
          this.interventoDaChiudere.hour_start,
        ] = item.inizio_tel.split(", ");
        let [day, month, year] =
          this.interventoDaChiudere.date_start.split("/");
        this.interventoDaChiudere.date_start = `${year}-${month}-${day}`;
        this.interventoDaChiudere.date_finish = `${year}-${month}-${day}`;
      } else {
        let [day, month, year] =
          this.interventoDaChiudere.data_inizio.split("/");
        this.interventoDaChiudere.date_start = `${year}-${month}-${day}`;
        this.interventoDaChiudere.date_finish = `${year}-${month}-${day}`;
      }
      this.dialogChiusura = true;
    },
    closeDialog() {
      this.dialogChiusura = false;
      this.interventoDaChiudere = {
        id_int: "",
        id_utente: "",
        nome_cognome: "",
        data_inizio: "",
        inizio_tel: "",
        termine_tel: "",
        date_start: "",
        hour_start: "",
        date_finish: "",
        hour_finish: "",
        gestito: "",
        rag_soc: "",
      };
    },
    chiudiInterventoConferma() {
      this.buttonLoading = true;
      this.axios
        .put(`${this.$store.state.url}interventi`, {
          id_utente: localStorage.getItem("user"),
          id_intervento: this.interventoDaChiudere.id_int,
          dipendente: this.interventoDaChiudere.id_utente,
          inizio_tel: `${this.interventoDaChiudere.date_start} ${this.interventoDaChiudere.hour_start}`,
          termine_tel: `${this.interventoDaChiudere.date_finish} ${this.interventoDaChiudere.hour_finish}`,
          gestito: this.interventoDaChiudere.gestito,
        })
        .then(() => {
          this.buttonLoading = false;
          this.getInterventi();
          this.$root.Snackbar.show({
            text: `L'intervento numero "${this.interventoDaChiudere.id_int}" è stato chiuso`,
          });
          this.closeDialog();
        })
        .catch((error) => {
          this.buttonLoading = false;
          this.closeDialog();
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.$emit("logout", error.response.data.message);
            }
          }
        });
    },
    cancellaIntervento(item) {
      this.interventoDelete = Object.assign({}, item);
      if (item.ricorrenza == 2 || item.ricorrenza == 3) {
        this.interventoRic = true;
      } else {
        this.interventoRic = false;
      }
      this.dialogDelete = true;
    },
    closeDelete() {
      this.deleteAll = null;
      this.dialogDelete = false;
      this.interventoDelete = {
        id_int: "",
        id_utente: "",
        nome_cognome: "",
        id_riferimento: "",
        ricorrenza: "",
        rag_soc: "",
        data_inizio: "",
        id_tipo_int: "",
      };
      this.interventoRic = false;
    },
    cancellaInterventoConferma() {
      this.buttonLoading = true;
      var tipoCanc = false;
      if (this.deleteAll) {
        tipoCanc = true;
      } else {
        tipoCanc = false;
      }
      this.axios
        .delete(`${this.$store.state.url}interventi`, {
          params: {
            id_utente: localStorage.getItem("user"),
            id_intervento: this.interventoDelete.id_int,
            dipendente: this.interventoDelete.id_utente,
            cancella_tutti: tipoCanc,
            id_riferimento: this.interventoDelete.id_riferimento,
            ricorrenze: this.interventoDelete.ricorrenza,
          },
        })
        .then(() => {
          this.buttonLoading = false;
          this.dialogDelete = false;
          this.getInterventi();
          if (this.deleteAll) {
            this.$root.Snackbar.show({
              text: `Gli interventi ricorrenti sono stati cancellati`,
            });
          } else {
            this.$root.Snackbar.show({
              text: `L'intervento numero "${this.interventoDelete.id_int}" è stato cancellato`,
            });
          }
          this.closeDelete();
        })
        .catch((error) => {
          this.buttonLoading = false;
          this.dialogDelete = false;
          this.getInterventi();
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.$emit("logout", error.response.data.message);
            }
          }
        });
    },
    getData(e) {
      this.currentItems = e;
    },

    downloadCSV() {
      this.dialogCSV = true;
      var csv = "";
      var itemsBuffer = this.itemsPerPage;
      this.itemsPerPage = -1;
      function timeDiffCalc(dateFuture, dateNow, tipo) {
        let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

        // calculate days
        var days = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= days * 86400;

        // calculate hours
        var hours = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= hours * 3600;

        // calculate minutes
        var minutes = Math.ceil(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= minutes * 60;

        let difference = "";
        function strToMins(t) {
          var s = t.split(":");
          return Number(s[0]) * 60 + Number(s[1]);
        }

        function minsToStr(t) {
          return Math.trunc(t / 60) + ":" + ("00" + (t % 60)).slice(-2);
        }

        if (tipo == 7 || tipo == 8) {
          days += 1;
        }

        if (days > 0) {
          var hour2 = dateFuture.toLocaleTimeString(navigator.language, {
            hour: "2-digit",
            minute: "2-digit",
          });
          var hour1 = dateNow.toLocaleTimeString(navigator.language, {
            hour: "2-digit",
            minute: "2-digit",
          });

          var hourBase = minsToStr(strToMins(hour2) - strToMins(hour1));
          var a = `${hourBase}:00`.split(":");

          // minutes are worth 60 seconds. Hours are worth 60 minutes.
          var secondsBase = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
          var newSeconds = days * secondsBase;

          // multiply by 1000 because Date() requires miliseconds
          var milisec = new Date(newSeconds * 1000);

          let ss = Math.floor(milisec / 1000);
          let mm = Math.ceil(ss / 60);
          let hh = Math.floor(mm / 60);

          ss = ss % 60;
          mm = mm % 60;
          hours = hh;
          minutes = mm;
        }

        // difference += days === 1 ? `${days} giorno, ` : `${days} giorni, `;
        difference += `${hours.toString().padStart(2, "0")}:`;

        difference += `${minutes.toString().padStart(2, "0")}`;

        return difference;
      }
      this.$nextTick(() => {
        var sommaLavoro = 0;
        var sommaFerie = 0;
        var sommaMalattia = 0;
        csv += `"No. intervento";"Dipendente";"Tipologia";"Cliente";"Indirizzo cliente";"Categoria";"Inizio pianificato";"Data/ora inizio";"Caricamento inizio";"Fine pianificato";"Data/ora fine";"Caricamento fine";"Stato";"Tempo di lavoro"\n`;

        this.currentItems.forEach(function (row) {
          var statusText;
          if (row["gestito"] == 0) {
            statusText = "Non gestito";
          } else if (row["gestito"] == 1) {
            statusText = "Iniziato";
          } else if (row["gestito"] == 2) {
            statusText = "Finito";
          } else if (row["gestito"] == 3) {
            if (row["id_tipo_int"] == 7) {
              statusText = "Ferie";
            } else {
              statusText = "Malattia";
            }
          } else {
            statusText = "Intervento non assegnato";
          }

          if (row["inizio_tel"] == undefined) {
            row["inizio_tel"] = "";
          } else {
            row["inizio_tel"] = row["inizio_tel"].replace(",", "");
          }
          if (row["termine_tel"] == undefined) {
            row["termine_tel"] = "";
          } else {
            row["termine_tel"] = row["termine_tel"].replace(",", "");
          }
          if (row["inizio_serv"] == undefined) {
            row["inizio_serv"] = "";
          } else {
            row["inizio_serv"] = row["inizio_serv"].replace(",", "");
          }
          if (row["termine_serv"] == undefined) {
            row["termine_serv"] = "";
          } else {
            row["termine_serv"] = row["termine_serv"].replace(",", "");
          }

          function rangeDate(date1, date2) {
            var [dateValues1, timeValues1] = date1.split(" ");
            var [day1, month1, year1] = dateValues1.split("/");
            if (timeValues1 == "") {
              timeValues1 = "00:00";
            }
            var [hours1, minutes1] = timeValues1.split(":");

            var inizio = new Date(
              parseInt(year1),
              parseInt(month1) - 1,
              parseInt(day1),
              parseInt(hours1),
              parseInt(minutes1)
            );

            var [dateValues2, timeValues2] = date2.split(" ");
            var [day2, month2, year2] = dateValues2.split("/");
            if (timeValues2 == "") {
              timeValues2 = "00:00:00";
            }
            var [hours2, minutes2] = timeValues2.split(":");

            var termine = new Date(
              parseInt(year2),
              parseInt(month2) - 1,
              parseInt(day2),
              parseInt(hours2),
              parseInt(minutes2)
            );
            return [inizio, termine];
          }

          var oreLavorate = "";
          if (row["id_tipo_int"] == 8 || row["id_tipo_int"] == 7) {
            if (row["data_ora_inizio"] != "" && row["data_ora_termine"] != "") {
              var [inizio1, termine1] = rangeDate(
                row["data_ora_inizio"],
                row["data_ora_termine"]
              );

              oreLavorate = timeDiffCalc(termine1, inizio1, row["id_tipo_int"]);
              var array1 = oreLavorate.split(":");
              var temp1 = 60 * array1[1] + 3600 * array1[0];

              if (row["id_tipo_int"] == 8) {
                sommaMalattia += temp1;
              } else if (row["id_tipo_int"] == 7) {
                sommaFerie += temp1;
              }
            }
          } else {
            if (row["inizio_tel"] != "" && row["termine_tel"] != "") {
              var [inizio2, termine2] = rangeDate(
                row["inizio_tel"],
                row["termine_tel"]
              );

              oreLavorate = timeDiffCalc(termine2, inizio2, row["id_tipo_int"]);
              var array2 = oreLavorate.split(":");
              var temp2 = 60 * array2[1] + 3600 * array2[0];

              sommaLavoro += temp2;
            }
          }

          csv += `"${row["id_int"]}";"${row["nome_cognome"]}";"${
            row["nome_tipo"]
          }";"${row["rag_soc"] == undefined ? "" : row["rag_soc"]}";"${
            row["indirizzo"] == undefined ? "" : row["indirizzo"]
          }";"${row["categoria"] == undefined ? "" : row["categoria"]}";"${
            row["data_ora_inizio"]
          }";"${row["inizio_tel"]}";"${row["inizio_serv"]}";"${
            row["data_ora_termine"] == undefined ? "" : row["data_ora_termine"]
          }";"${row["termine_tel"]}";"${
            row["termine_serv"]
          }";"${statusText}";"${oreLavorate}";\n`;
        });

        function convertSomma(seconds) {
          seconds = Number(seconds);
          var h = Math.floor(seconds / 3600)
            .toString()
            .padStart(2, "0");
          var m = Math.floor((seconds % 3600) / 60)
            .toString()
            .padStart(2, "0");
          return `${h}:${m}`;
        }

        sommaMalattia = convertSomma(sommaMalattia);
        sommaFerie = convertSomma(sommaFerie);
        sommaLavoro = convertSomma(sommaLavoro);

        // sommaLavoro = new Date(sommaLavoro * 1000)
        //   .toISOString()
        //   .substring(11, 16);
        csv += `;;;;;;;;;;;;;ORE LAVORO: ${sommaLavoro};\n;;;;;;;;;;;;;ORE FERIE: ${sommaFerie}\n;;;;;;;;;;;;;ORE MALATTIA: ${sommaMalattia}`;

        var universalBOM = "\uFEFF";
        var csvData = new Blob([universalBOM + csv], {
          type: "text/csv;charset=utf-8",
        });
        this.fileCSV = URL.createObjectURL(csvData);
        var d = new Date();
        this.dateNow =
          d.getFullYear() +
          "" +
          (d.getMonth() + 1) +
          "" +
          d.getDate() +
          "" +
          d.getHours() +
          "" +
          d.getMinutes() +
          "" +
          d.getSeconds();
        this.itemsPerPage = itemsBuffer;
      });
    },
  },
  watch: {
    search: function () {
      this.$store.state.searchInterventi = this.search;
    },
    filtersObj: function () {
      this.$store.state.filtersInterventi = this.filtersObj;
    },
    dialogChiusura(val) {
      val || this.closeDialog();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  beforeDestroy: function () {
    clearInterval(this.interval);
    this.interval = null;
  },
};
</script>

<style>
#riepilogo .search {
  padding: 0;
  margin: 0;
}

.yellow-bg {
  background-color: lightyellow;
}
</style>