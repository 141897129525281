import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Aggiorna from '../views/AggiornaPswd.vue'
import Reset from '../views/ResetPswd.vue'
import Recupera from '../views/RecuperaPswd.vue'
import Home from '../views/Home.vue'
import Clienti from '../views/Clienti.vue'
import Dipendenti from '../views/Dipendenti.vue'
import CreaIntervento from '../views/CreaIntervento.vue'
import DettaglioIntervento from '../views/DettaglioIntervento.vue'
import Riepilogo from '../views/Riepilogo.vue'
import Note from '../views/Note.vue'
import PageError from '../views/PageError.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: { title: 'Login' }
  },
  //Primo login
  {
    path: '/aggiorna-password/',
    name: 'Aggiorna',
    component: Aggiorna,
    meta: { title: 'Aggiorna password' }
  },
  //Password dimenticata
  {
    path: '/reset-password/:jwt',
    name: 'Reset',
    component: Reset,
    meta: { title: 'Reset password' }
  },
  //Invio mail per cambio password
  {
    path: '/recupera-password',
    name: 'Recupera',
    component: Recupera,
    meta: { title: 'Recupera password' }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { title: 'Home' }
  },
  {
    path: '/clienti',
    name: 'Clienti',
    component: Clienti,
    meta: { title: 'Clienti' }
  },
  {
    path: '/dipendenti',
    name: 'Dipendenti',
    component: Dipendenti,
    meta: { title: 'Dipendenti' }
  },
  {
    path: '/crea-intervento',
    name: 'CreaIntervento',
    component: CreaIntervento,
    meta: { title: 'Crea intervento' }
  },
  {
    path: '/riepilogo-interventi',
    name: 'Riepilogo',
    component: Riepilogo,
    meta: { title: 'Riepilogo interventi' }
  },
  {
    path: '/dettaglio-intervento/:id',
    name: 'DettaglioIntervento',
    component: DettaglioIntervento,
    meta: { title: 'Dettaglio intervento' },
    props: true
  },
  {
    path: '/note',
    name: 'Note',
    component: Note,
    meta: { title: 'Note generali' }
  },
  {
    path: '*',
    name: 'PageError',
    component: PageError,
    meta: { title: '404 Not Found' }
  },
]

const router = new VueRouter({
  routes
})

export default router
