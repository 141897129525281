var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{style:(_vm.$vuetify.breakpoint.xs
      ? 'height: calc(100vh - 64px); margin-top: 64px'
      : 'height: calc(100vh - 128px); margin-top: 128px'),attrs:{"id":"reset"}},[_c('v-card',{staticClass:"pa-5 mx-auto",attrs:{"width":_vm.$vuetify.breakpoint.xs ? '100%' : '50%'}},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.conferma()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"label":"Nuova password","required":"","autocomplete":"new-password","append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show1 ? 'text' : 'password',"rules":[
          (v) => !!v || 'Campo obbligatorio',
          (v) =>
            v.length >= 6 ||
            'La password deve essere lunga almeno 6 caratteri!',
        ]},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.pswd1),callback:function ($$v) {_vm.pswd1=$$v},expression:"pswd1"}}),_c('v-text-field',{attrs:{"label":"Ripeti password","required":"","autocomplete":"new-password","append-icon":_vm.show2 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show2 ? 'text' : 'password',"rules":[(v) => !!v || 'Campo obbligatorio']},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.pswd2),callback:function ($$v) {_vm.pswd2=$$v},expression:"pswd2"}}),(!_vm.validatePswd && _vm.pswd1 && _vm.pswd2)?_c('div',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("Le due password non corrispondono!")])]):_vm._e(),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary","disabled":_vm.validatePswd && _vm.valid ? false : true,"type":"submit","rounded":"","loading":_vm.buttonLoading}},[_vm._v(" Conferma ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }