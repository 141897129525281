<template>
  <v-container
    id="recupera"
    :style="
      $vuetify.breakpoint.xs
        ? 'height: calc(100vh - 64px); margin-top: 64px'
        : 'height: calc(100vh - 128px); margin-top: 128px'
    "
  >
    <v-card
      :width="$vuetify.breakpoint.xs ? '100%' : '50%'"
      class="pa-5 mx-auto"
    >
      <v-form ref="form" @submit.prevent="conferma()" v-model="valid">
        <v-text-field
          v-model="ambiente"
          label="Azienda"
          required
          autocomplete="azienda"
          :maxlength="6"
          counter="6"
        ></v-text-field>
        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          required
          autocomplete="email"
        ></v-text-field>
        <v-btn
          color="primary"
          class="mt-2"
          :disabled="!valid"
          type="submit"
          rounded
          :loading="buttonLoading"
        >
          Conferma
        </v-btn>
      </v-form></v-card
    >
  </v-container>
</template>

<script>
export default {
  name: "Recupera",
  data: () => ({
    valid: false,
    buttonLoading: false,
    email: "",
    message: "",
    emailRules: [
      (v) => !!v || "Campo obbligatorio",
      (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
    ],
    ambiente: "",
  }),
  mounted() {
    this.id = localStorage.getItem("user");
  },
  methods: {
    conferma() {
      this.buttonLoading = true;
      this.axios
        .post(`${this.$store.state.url}recupera`, {
          email: this.email.trim(),
          ambiente: this.ambiente,
        })
        .then(() => {
          this.buttonLoading = false;
          this.$store.state.loading = false;
          this.$root.Snackbar.show({
            text: "Verifica la casella mail per proseguire col reset",
          });
        })
        .catch((error) => {
          this.buttonLoading = false;
          if (error.response == undefined) {
            this.$root.Snackbar.show({
              text: "Verificare i dati inseriti",
            });
          } else {
            this.$root.Snackbar.show({
              text: error.response.data.message,
            });
          }
        });
    },
  },
  // watch: {
  //   pswd1: {
  //     handler() {
  //       this.validate();
  //     },
  //     immediate: true,
  //   },
  //   pswd2: {
  //     handler() {
  //       this.validate();
  //     },
  //     immediate: true,
  //   },
  // },
};
</script>