var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{attrs:{"id":"gestionale"}},[_c('v-card',{staticClass:"overflow-hidden",staticStyle:{"border-radius":"0"}},[(_vm.$route.path != '/' && _vm.$route.name != 'PageError')?_c('v-app-bar',{staticClass:"navbar",attrs:{"absolute":"","dark":"","height":_vm.$vuetify.breakpoint.xs ? '64px' : '124px',"src":require("./assets/Immagine-BarraNav-v2.jpg")},scopedSlots:_vm._u([{key:"img",fn:function({ props }){return [_c('v-img',_vm._b({attrs:{"gradient":"to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"}},'v-img',props,false))]}}],null,false,3283470206)},[(
          _vm.$route.path != '/' &&
          _vm.$route.path != '/aggiorna-password' &&
          _vm.$route.path != '/recupera-password' && _vm.$route.name != 'Reset'
        )?_c('v-app-bar-nav-icon',{class:_vm.$vuetify.breakpoint.xs ? 'align-self-center' : 'align-self-start',on:{"click":function($event){_vm.drawer = !_vm.drawer}}}):_vm._e(),_c('v-toolbar-title',{class:_vm.$vuetify.breakpoint.xs ? 'align-self-center' : 'align-self-end pb-2',attrs:{"width":"100%"}},[_vm._v(_vm._s(_vm.pageTitle))]),_c('v-spacer'),(
          _vm.$route.path != '/' &&
          _vm.$route.path != '/aggiorna-password' &&
          _vm.$route.path != '/recupera-password' && _vm.$route.name != 'Reset'
        )?_c('v-btn',{class:_vm.$vuetify.breakpoint.xs ? 'align-self-center' : 'align-self-start',attrs:{"icon":""},on:{"click":function($event){_vm.$route.path != '/note' ? _vm.$router.push('/note') : ''}}},[_c('v-badge',{attrs:{"content":_vm.note,"value":_vm.note,"color":"red","overlap":""}},[_c('v-icon',[_vm._v(" mdi-bell ")])],1)],1):_vm._e(),(
          _vm.$route.path != '/' &&
          _vm.$route.path != '/aggiorna-password' &&
          _vm.$route.path != '/recupera-password' && _vm.$route.name != 'Reset'
        )?_c('v-btn',{class:_vm.$vuetify.breakpoint.xs ? 'align-self-center' : 'align-self-start',attrs:{"icon":""},on:{"click":function($event){return _vm.logout('')}}},[_c('v-icon',[_vm._v("mdi-export")])],1):_vm._e()],1):_vm._e(),_c('v-navigation-drawer',{staticStyle:{"background-image":"linear-gradient("},attrs:{"fixed":"","temporary":"","bottom":"","mobile-breakpoint":"540"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',_vm._l((_vm.menu),function(item,i){return _c('v-list-item',{key:i,attrs:{"link":"","to":item.href}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icona))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.nome))])],1)],1)}),1)],1),_c('v-sheet',{class:_vm.$route.path == '/' ? 'overflow-y-auto bg-color' : 'overflow-y-auto'},[(_vm.checkHeader)?_c('router-view',{on:{"logout":_vm.logout,"get-notifiche":_vm.getNumeroNote}}):_vm._e(),(_vm.$store.state.loading)?_c('Loading'):_vm._e(),_c('Snackbar',{ref:"snackbar"})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }