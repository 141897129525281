<template>
  <v-container id="home" :style="
      $vuetify.breakpoint.xs
        ? 'height: calc(100vh - 64px); margin-top: 64px'
        : 'height: calc(100vh - 128px); margin-top: 128px'
    ">
    <v-row>
      <v-col
        v-for="(cat, i) in categories"
        :key="i"
        class="d-flex child-flex"
        cols="6"
        sm="3"
        md="2"
      >
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              :elevation="hover ? 24 : 2"
              class="transition-swing"
              link
              :to="cat.href"
            >
              <v-img
                :src="cat.foto"
                aspect-ratio="1"
                class="white--text align-end"

              >
                <!-- <v-card-text
                  >
                  <h4 v-if="$vuetify.breakpoint.xs">{{ cat.nome }}</h4>
                  <h3 v-if="!$vuetify.breakpoint.xs">{{ cat.nome }}</h3></v-card-text
                > -->
              </v-img>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    categories: [
      {
        nome: "CLIENTI",
        foto: require('@/assets/Bottone-Clienti.jpg'),
        href: "/clienti",
      },
      {
        nome: "DIPENDENTI",
        foto: require('@/assets/Bottone-Dipendenti.jpg'),
        href: "/dipendenti",
      },
        {
          nome: "CREA INTERVENTO",
          foto: require('@/assets/Bottone-Crea-Intervento.jpg'),
          href: "/crea-intervento",
        },
      {
        nome: "RIEPILOGO INTERVENTI",
        foto: require('@/assets/Bottone-Riepilogo-Interventi.jpg'),
        href: "/riepilogo-interventi",
      },
      {
        nome: "NOTE GENERALI",
        foto: require('@/assets/Bottone-Note-Generali.jpg'),
        href: "/note",
      },
    ],
  }),
};
</script>

<style>
#home .v-sheet.v-card {
  cursor: pointer;
}
</style>