<template>
  <v-container
    id="aggiorna"
    :style="
      $vuetify.breakpoint.xs
        ? 'height: calc(100vh - 64px); margin-top: 64px'
        : 'height: calc(100vh - 128px); margin-top: 128px'
    "
  >
    <v-card
      :width="$vuetify.breakpoint.xs ? '100%' : '50%'"
      class="pa-5 mx-auto"
    >
      <v-form ref="form" @submit.prevent="conferma()" v-model="valid">
        <v-text-field
          v-model="pswd1"
          label="Nuova password"
          required
          autocomplete="new-password"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          @click:append="show1 = !show1"
          :rules="[
            (v) => !!v || 'Campo obbligatorio',
            (v) =>
              v.length >= 6 ||
              'La password deve essere lunga almeno 6 caratteri!',
          ]"
        ></v-text-field>
        <v-text-field
          v-model="pswd2"
          label="Ripeti password"
          required
          autocomplete="new-password"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show2 ? 'text' : 'password'"
          @click:append="show2 = !show2"
          :rules="[(v) => !!v || 'Campo obbligatorio']"
        ></v-text-field>
        <div v-if="!validatePswd && pswd1 && pswd2">
          <span style="color: red">Le due password non corrispondono!</span>
        </div>

        <v-btn
          color="primary"
          class="mt-2"
          :disabled="validatePswd && valid ? false : true"
          type="submit"
          rounded
          :loading="buttonLoading"
        >
          Conferma
        </v-btn>
      </v-form></v-card
    >
  </v-container>
</template>

<script>
import router from "../router";

export default {
  name: "Aggiorna",
  data: () => ({
    valid: false,
    buttonLoading: false,
    id: "",
    pswd1: "",
    pswd2: "",
    show1: false,
    show2: false,
    message: "",
    validatePswd: false,
    ambiente: ""
  }),
  mounted() {
    if (
      localStorage.getItem("user") != null &&
      localStorage.getItem("user") != undefined &&
      localStorage.getItem("user") != "" &&
      localStorage.getItem("ambiente") != null &&
      localStorage.getItem("ambiente") != undefined &&
      localStorage.getItem("ambiente") != ""
    ) {
      this.id = localStorage.getItem("user");
      this.ambiente = localStorage.getItem("ambiente");
    } else {
      router.push("/");
      this.$root.Snackbar.show({
        text: "Effettuare il login",
      });
    }
  },
  methods: {
    validate() {
      if (this.pswd1 == this.pswd2) {
        this.validatePswd = true;
      } else {
        this.validatePswd = false;
      }
    },
    conferma() {
      this.buttonLoading = true;
      this.axios
        .post(`${this.$store.state.url}aggiorna`, {
          id_utente: this.id,
          password: this.pswd2,
          source: "web",
          ambiente: this.ambiente
        })
        .then(() => {
          this.buttonLoading = false;
          this.$router.push("/home");
        })
        .catch((error) => {
          this.buttonLoading = false;
          if (error.response != undefined) {
            this.$root.Snackbar.show({
              text: error.response.data.message,
            });
          }
        });
    },
  },
  watch: {
    pswd1: {
      handler() {
        this.validate();
      },
      immediate: true,
    },
    pswd2: {
      handler() {
        this.validate();
      },
      immediate: true,
    },
  },
};
</script>